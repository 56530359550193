import { Box, IconButton, Snackbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

const SnackbarError = ({ error, onClose }) => {
  return (
    <Snackbar
      open={error}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      ContentProps={{
        sx: {
          bgcolor: "white",
          color: "black",
          display: "flex",
          justifyContent: "center",
          border: "1px solid red",
        },
      }}
      message={
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <ErrorRoundedIcon sx={{ color: "error.main" }} />
          <Typography variant="body1">{error}</Typography>
        </Box>
      }
      action={
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
      autoHideDuration={15000}
      onClose={onClose}
    />
  );
};

export default SnackbarError;
