import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import PostClient from "../api/PostClient";
import {
  Box,
  CircularProgress,
  Divider,
  TextField,
  Typography,
  InputAdornment,
  Checkbox,
  Dialog,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  FormGroup,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { CustomButton } from "./buttons/CustomButton";
import SaveIcon from "@mui/icons-material/Save";
import SnackbarPositive from "./snackbars/SnackbarPositive";
import CountrySelect from "./client-dialog/CountrySelector";
import { Errors } from "../api/error";
import SnackbarError from "./snackbars/SnackbarError";
const StyledTextField = styled(TextField)(({ theme }) => ({
  minWidth: "10rem",
  width: "100%",
  margin: "0.5rem",
}));

const StyledZipCodeBox = styled(Box)(({ theme }) => ({
  maxWidth: "100%",
  display: "flex",
  alignItems: "center",
}));

const StyledZipCodeFirstPart = styled(TextField)(({ theme }) => ({
  maxWidth: "4rem",
}));
const StyledZipCodeSecondPart = styled(TextField)(({ theme }) => ({
  maxWidth: "6rem",
}));

const FormDialog = ({ open, handleClose, handleRefetch }) => {
  const theme = useTheme();

  const [postClientError, setPostClientError] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [autoFillCorAddress, setAutoFillCorAddres] = useState(false);
  const [autoFillInvoiceEmail, setAutoFillInvoiceEmail] = useState(false);
  const [country, setCountry] = useState({
    code: "PL",
    label: "Polska",
    phone: "48",
  });
  const [citizenShip, setCitizenShip] = useState({
    code: "PL",
    label: "Polska",
    phone: "48",
  });
  const handleSetCountry = (val) => {
    setFormData({
      ...formData,
      country: val.code,
    });
  };
  const handleSetCitizenship = (val) => {
    setFormData({
      ...formData,
      citizenship: val.code,
    });
  };
  const [isPassport, setIsPassport] = useState(false);
  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    birth_date: "",
    pesel: "",
    gender_field: "",
    phone_number: "",
    contact_email: "",
    invoice_email: "",
    address_street_name: "",
    address_house_number: "",
    address_apartment_number: "",
    address_city_name: "",
    address_postal_code: "",
    correspondence_street_name: "",
    correspondence_house_number: "",
    correspondence_apartment_number: "",
    correspondence_city_name: "",
    correspondence_postal_code: "",
    consent_receive_commercial_information_electronic_means: true,
    consent_to_contact_via_phone_textmsg: true,
    seller_comment: "",
    passport: "",
    country: "",
    citizenship: "",
  });

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    birth_date: "",
    pesel: "",
    gender_field: "",
    phone_number: "",
    contact_email: "",
    invoice_email: "",
    address_street_name: "",
    address_house_number: "",
    address_apartment_number: "",
    address_city_name: "",
    address_postal_code: "",
    correspondence_street_name: "",
    correspondence_house_number: "",
    correspondence_apartment_number: "",
    correspondence_city_name: "",
    correspondence_postal_code: "",
    consent_receive_commercial_information_electronic_means: false,
    consent_to_contact_via_phone_textmsg: false,
    seller_comment: "",
    preferred_contact_language_ukrainian_or_russian: "Polski",
    passport: "",
    country: "PL",
    citizenship: "PL",
  });

  const handleOpenSnackbar = () => {
    setIsOpen(true);
  };

  const handleCloseSnackbar = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpen(false);
  };

  const [isLoading, setIsLoading] = useState(false);
  const timer = useRef();

  const resetForm = () => {
    setFormData({
      first_name: "",
      last_name: "",
      birth_date: "",
      pesel: "",
      gender_field: "",
      phone_number: "",
      contact_email: "",
      invoice_email: "",
      address_street_name: "",
      address_house_number: "",
      address_apartment_number: "",
      address_city_name: "",
      address_postal_code: "",
      correspondence_street_name: "",
      correspondence_house_number: "",
      correspondence_apartment_number: "",
      correspondence_city_name: "",
      correspondence_postal_code: "",
      consent_receive_commercial_information_electronic_means: false,
      consent_to_contact_via_phone_textmsg: false,
      seller_comment: "",
      preferred_contact_language_ukrainian_or_russian: "Polski",
      passport: "",
      country: "PL",
      citizenship: "PL",
    });
    setAutoFillCorAddres(false);
    setAutoFillInvoiceEmail(false);
  };

  const handlePostalCode = (value, part, field) => {
    if (formData[field]) {
      const [firstPart, secondPart] = formData[field].split("-");
      const newPostal =
        part === "first" ? `${value}-${secondPart}` : `${firstPart}-${value}`;
      setFormData((prevState) => ({
        ...prevState,
        [field]: newPostal,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [field]: part === "first" ? `${value}-` : `-${value}`,
      }));
    }
  };

  const parsePesel = () => {
    if (formData.pesel.length < 11) {
      setError({ ...error, pesel: "Podany numer PESEL jest błędny" });
    } else {
      var aInt = new Array();
      for (let i = 0; i < 11; i++) {
        aInt[i] = parseInt(formData.pesel.substring(i, i + 1));
      }
      var wagi = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 1];
      var sum = 0;
      for (let i = 0; i < 11; i++) sum += wagi[i] * aInt[i];
      if (sum % 10 !== 0) {
        setError({ ...error, pesel: "Podany numer PESEL jest błędny" });
      }
      let year = 1900 + aInt[0] * 10 + aInt[1];
      if (aInt[2] >= 2 && aInt[2] < 8) year += Math.floor(aInt[2] / 2) * 100;
      if (aInt[2] >= 8) year -= 100;

      var month = (aInt[2] % 2) * 10 + aInt[3];
      var day = aInt[4] * 10 + aInt[5];
      var gender = aInt[9] % 2 === 1 ? "M" : "K";
      setFormData({
        ...formData,
        birth_date: `${year}-${
          month.toString().length === 1 ? `0${month}` : month
        }-${day.toString().length === 1 ? `0${day}` : day}`,
        gender_field: gender,
      });
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    if (autoFillCorAddress) {
      setFormData({
        ...formData,
        correspondence_street_name: formData.address_street_name,
        correspondence_house_number: formData.address_house_number,
        correspondence_apartment_number: formData.address_apartment_number,
        correspondence_city_name: formData.address_city_name,
        correspondence_postal_code: formData.address_postal_code,
      });
    } else {
      setFormData({
        ...formData,
        correspondence_street_name: "",
        correspondence_house_number: "",
        correspondence_apartment_number: "",
        correspondence_city_name: "",
        correspondence_postal_code: "",
      });
    }
  }, [autoFillCorAddress]);

  const handlePostClient = () => {
    if (formData) {
      setError("");

      setIsLoading(true);
      let data = {
        ...formData,
        passport: formData.passport === "" ? null : formData.passport,
        pesel: formData.pesel === "" ? null : formData.pesel,
        citizenship: formData.citizenship === "" ? "PL" : formData.citizenship,
        country: formData.country === "" ? "PL" : formData.country,
        correspondence_street_name: autoFillCorAddress
          ? formData.address_street_name
          : formData.correspondence_street_name,
        correspondence_house_number: autoFillCorAddress
          ? formData.address_house_number
          : formData.correspondence_house_number,
        correspondence_apartment_number: autoFillCorAddress
          ? formData.address_apartment_number
          : formData.correspondence_apartment_number,
        correspondence_city_name: autoFillCorAddress
          ? formData.address_city_name
          : formData.correspondence_city_name,
        correspondence_postal_code: autoFillCorAddress
          ? formData.address_postal_code
          : formData.correspondence_postal_code,
        invoice_email: autoFillInvoiceEmail
          ? formData.contact_email
          : formData.invoice_email,
      };
      PostClient(data)
        .then((res) => {
          if (res && res.status === 201) {
            handleClose();
            handleOpenSnackbar();
            handleRefetch();
            resetForm();
          }
        })
        .catch((err) => {
          if (err.response.status === Errors.BAD_REQUEST) {
            setError(err.response.data.error.details);
            setPostClientError("Wystąpił problem w trakcie zapisu danych");
          }

          if (err.response.status === Errors.PESEL_ALREADY_EXISTS) {
            setError({
              pesel: ["client with this pesel already exists."],
            });
            setPostClientError("Klient o tym numerze PESEL już istnieje");
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Box>
      <SnackbarError
        error={postClientError}
        onClose={() => setPostClientError(null)}
      />
      <Dialog
        open={open}
        onClose={() => {
          setError("");

          handleClose();
        }}
        PaperProps={{
          component: "form",
          sx: {
            display: "flex",
            minWidth: "80rem",
            maxWidth: "120rem",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h4">Formularz dodawania klienta</Typography>
        </DialogTitle>
        <Divider
          flexItem
          sx={{
            backgroundColor: theme.palette.primary.main,
            height: "5px",
            marginLeft: "1rem",
            marginRight: "1rem",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-evenly",
            paddingLeft: "2rem",
            paddingRight: "2rem",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "1rem",
              height: "100%",
              "& .MuiTextField-root": { m: 1, width: "40ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Typography variant="h4">Podstawowe informacje</Typography>
            <StyledTextField
              size="small"
              label="Imię"
              variant="outlined"
              id="outlined-error"
              helperText={error.first_name && "To pole nie może być puste"}
              error={error.first_name ? true : false}
              type="text"
              required
              value={formData.first_name}
              onChange={(event) => {
                setFormData({ ...formData, first_name: event.target.value });
              }}
            />

            <StyledTextField
              size="small"
              label="Nazwisko"
              variant="outlined"
              id="outlined-error"
              required
              helperText={error.last_name && "To pole nie może być puste"}
              error={error.last_name ? true : false}
              value={formData.last_name}
              onChange={(event) => {
                setFormData({ ...formData, last_name: event.target.value });
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPassport}
                  onChange={() => {
                    setIsPassport((prev) => !prev);
                    setFormData({ ...formData, passport: "", pesel: "" });
                  }}
                />
              }
              label="Użyj paszportu zamiast numeru PESEL"
            />
            {isPassport ? (
              <StyledTextField
                sx={{
                  '& input[type="number"]::-webkit-inner-spin-button': {
                    display: "none",
                  },
                  "& input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                }}
                size="small"
                label="Paszport"
                variant="outlined"
                id="outlined-error"
                type="text"
                required
                // helperText={
                //   error.passport &&
                //   error.passport[0] ===
                //     "client with this passport already exists."
                //     ? "Klient o tym numerze paszportu już istnieje"
                //     : error.passport[0] === "This field may not be blank." &&
                //       "To pole nie może być puste"
                // }
                error={error.passport ? true : false}
                value={formData.passport}
                onChange={(event) => {
                  setFormData({ ...formData, passport: event.target.value });
                }}
              />
            ) : (
              <StyledTextField
                sx={{
                  '& input[type="number"]::-webkit-inner-spin-button': {
                    display: "none",
                  },
                  "& input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                }}
                size="small"
                label="Pesel"
                variant="outlined"
                id="outlined-error"
                type="number"
                required
                helperText={
                  error.pesel
                    ? error.pesel[0] ===
                      "client with this pesel already exists."
                      ? "Klient o tym numerze PESEL już istnieje"
                      : error.pesel[0] === "This field may not be blank."
                      ? "To pole nie może być puste"
                      : "Podany numer PESEL jest błędny"
                    : "Naciśnij Enter, by uzupełnić datę urodzenia i płeć na podstawie peselu"
                }
                error={error.pesel ? true : false}
                value={formData.pesel}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    parsePesel();
                  }
                }}
                onChange={(event) => {
                  setFormData({ ...formData, pesel: event.target.value });
                }}
              />
            )}
            <StyledTextField
              size="small"
              label="Data urodzenia"
              variant="outlined"
              id="outlined-error"
              required
              helperText={
                error.birth_date
                  ? error.birth_date[0] ===
                      "Date has wrong format. Use one of these formats instead: YYYY-MM-DD." &&
                    "Błędny format daty"
                  : "Podaj datę w formacie RRRR-MM-DD"
              }
              error={error.birth_date ? true : false}
              value={formData.birth_date}
              onChange={(event) => {
                setFormData({ ...formData, birth_date: event.target.value });
              }}
            />

            <FormControl
              required
              error={error.gender_field && error.gender_field[0] ? true : false}
            >
              <FormLabel id="demo-radio-buttons-group-label">Płeć</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                value={formData.gender_field}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    gender_field: event.target.value,
                  });
                }}
              >
                <FormControlLabel
                  value="K"
                  control={<Radio />}
                  label="Kobieta"
                />
                <FormControlLabel
                  value="M"
                  control={<Radio />}
                  label="Mężczyzna"
                />
              </RadioGroup>

              <FormHelperText sx={{ color: "red" }}>
                {error.gender_field &&
                  (error.gender_field[0] === "Pesel - gender mismatch"
                    ? "Zaznaczona płeć nie zgadza się z numerem PESEL."
                    : "Wybierz jedną z podanych opcji")}
              </FormHelperText>
            </FormControl>
            <StyledTextField
              size="small"
              label="Telefon kontaktowy"
              variant="outlined"
              id="outlined-error"
              helperText={
                error.phone_number &&
                (error.phone_number[0] === "A valid integer is required." ||
                  error.phone_number[0] === "Enter a valid value.") &&
                "Numer telefonu jest niepoprawny"
              }
              error={error.phone_number ? true : false}
              required
              value={formData.phone_number}
              // onKeyDown={() => {
              //   if (formData.phone_number.length >= 9) {
              //     var reg = /^\+48\d[0-9+]{8,13}$/;
              //     if (!reg.test(formData.phone_number)) {
              //       setError({
              //         ...error,
              //         phone_number: "Numer telefonu jest błędny",
              //       });
              //     } else {
              //       setError("");
              //     }
              //   }
              // }}
              onChange={(event) => {
                setFormData({ ...formData, phone_number: event.target.value });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+48</InputAdornment>
                ),
              }}
            />

            <StyledTextField
              size="small"
              label="Adres email"
              variant="outlined"
              id="outlined-error"
              required
              helperText={
                error.contact_email &&
                (error.contact_email[0] === "Enter a valid email address."
                  ? "Wprowadź poprawny adres email"
                  : error.contact_email[0] === "This field may not be blank." &&
                    "To pole nie może być puste")
              }
              error={error.contact_email ? true : false}
              value={formData.contact_email}
              onChange={(event) => {
                setFormData({ ...formData, contact_email: event.target.value });
              }}
              inputProps={{ type: "email" }}
            />
            {!autoFillInvoiceEmail && (
              <StyledTextField
                size="small"
                label="Adres email FV"
                variant="outlined"
                id="outlined-error"
                required
                helperText={
                  error.invoice_email &&
                  (error.invoice_email[0] === "Enter a valid email address."
                    ? "Wprowadź poprawny adres email"
                    : error.invoice_email[0] ===
                        "This field may not be blank." &&
                      "To pole nie może być puste")
                }
                error={error.invoice_email ? true : false}
                value={formData.invoice_email}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    invoice_email: event.target.value,
                  });
                }}
              />
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={autoFillInvoiceEmail}
                  onChange={(event) => {
                    setAutoFillInvoiceEmail(event.target.checked);
                    if (event.target.checked === true) {
                      setFormData({
                        ...formData,
                        invoice_email: formData.contact_email,
                      });
                    }
                    if (event.target.checked === false) {
                      setFormData({
                        ...formData,
                        invoice_email: "",
                      });
                    }
                  }}
                />
              }
              label="Taki sam jak adres email do kontaktu"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "1rem",
              height: "100%",
              "& .MuiTextField-root": { m: 1, width: "40ch" },
            }}
          >
            <Typography variant="h4">Adres zamieszkania</Typography>
            <StyledTextField
              size="small"
              label="Ulica"
              variant="outlined"
              id="outlined-error"
              required
              helperText={
                error.address_street_name &&
                error.address_street_name[0] ===
                  "This field may not be blank." &&
                "To pole nie może być puste"
              }
              error={error.address_street_name ? true : false}
              value={formData.address_street_name}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  address_street_name: event.target.value,
                });
              }}
            />
            <StyledTextField
              size="small"
              label="Numer budynku"
              variant="outlined"
              id="outlined-error"
              type="string"
              sx={{
                '& input[type="number"]::-webkit-inner-spin-button': {
                  display: "none",
                },
                "& input[type=number]": {
                  "-moz-appearance": "textfield",
                },
              }}
              helperText={
                error.address_house_number &&
                error.address_house_number[0] ===
                  "This field may not be blank." &&
                "To pole nie może być puste"
              }
              error={error.address_house_number ? true : false}
              required
              value={formData.address_house_number}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  address_house_number: event.target.value,
                });
              }}
            />
            <StyledTextField
              size="small"
              label="Numer mieszkania"
              variant="outlined"
              type="string"
              error={error.address_apartment_number ? true : false}
              value={formData.address_apartment_number}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  address_apartment_number: event.target.value,
                });
              }}
            />
            <StyledTextField
              size="small"
              label="Miasto"
              variant="outlined"
              id="outlined-error"
              helperText={
                error.address_city_name &&
                error.address_city_name[0] === "This field may not be blank." &&
                "To pole nie może być puste"
              }
              error={error.address_city_name ? true : false}
              required
              value={formData.address_city_name}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  address_city_name: event.target.value,
                });
              }}
            />
            <StyledZipCodeBox>
              <StyledZipCodeFirstPart
                size="small"
                label="00"
                variant="outlined"
                id="outlined-error"
                required
                error={error.address_postal_code ? true : false}
                onChange={(event) => {
                  handlePostalCode(
                    event.target.value,
                    "first",
                    "address_postal_code"
                  );
                }}
                inputProps={{ maxLength: 2 }}
              />
              <Typography variant="h4"> - </Typography>
              <StyledZipCodeSecondPart
                size="small"
                label="000"
                variant="outlined"
                id="outlined-error"
                max="3"
                required
                error={error.address_postal_code ? true : false}
                onChange={(event) => {
                  handlePostalCode(
                    event.target.value,
                    "second",
                    "address_postal_code"
                  );
                }}
                inputProps={{ maxLength: 3 }}
              />
            </StyledZipCodeBox>
            <Typography variant="h4">Adres korespondencyjny</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoFillCorAddress}
                  onChange={(event) => {
                    setAutoFillCorAddres(event.target.checked);
                    if (event.target.checked === true) {
                      setFormData({
                        ...formData,
                        correspondence_street_name:
                          formData.address_street_name,
                        correspondence_house_number:
                          formData.address_house_number,
                        correspondence_apartment_number:
                          formData.address_apartment_number,
                        correspondence_city_name: formData.address_city_name,
                        correspondence_postal_code:
                          formData.address_postal_code,
                      });
                    } else {
                      setFormData({
                        ...formData,
                        correspondence_street_name: "",
                        correspondence_house_number: "",
                        correspondence_apartment_number: "",
                        correspondence_city_name: "",
                        correspondence_postal_code: "",
                      });
                    }
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Taki sam jak adres zamieszkania"
            />
            {autoFillCorAddress ? (
              <></>
            ) : (
              <>
                <StyledTextField
                  size="small"
                  label="Ulica"
                  variant="outlined"
                  id="outlined-error"
                  required
                  helperText={
                    error.correspondence_street_name &&
                    error.correspondence_street_name[0] ===
                      "This field may not be blank." &&
                    "To pole nie może być puste"
                  }
                  error={error.correspondence_street_name ? true : false}
                  value={formData.correspondence_street_name}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      correspondence_street_name: event.target.value,
                    });
                  }}
                />
                <StyledTextField
                  size="small"
                  label="Numer budynku"
                  variant="outlined"
                  id="outlined-error"
                  required
                  type="string"
                  sx={{
                    '& input[type="number"]::-webkit-inner-spin-button': {
                      display: "none",
                    },
                    "& input[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  }}
                  helperText={
                    error.correspondence_house_number &&
                    error.correspondence_house_number[0] ===
                      "This field may not be blank." &&
                    "To pole nie może być puste"
                  }
                  error={error.correspondence_house_number ? true : false}
                  value={formData.correspondence_house_number}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      correspondence_house_number: event.target.value,
                    });
                  }}
                />
                <StyledTextField
                  size="small"
                  label="Numer mieszkania"
                  variant="outlined"
                  // id="outlined-error"
                  // required
                  helperText={
                    error.correspondence_apartment_number &&
                    error.correspondence_apartment_number[0] ===
                      "This field may not be blank." &&
                    "To pole nie może być puste"
                  }
                  error={error.correspondence_apartment_number ? true : false}
                  value={formData.correspondence_apartment_number}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      correspondence_apartment_number: event.target.value,
                    });
                  }}
                />
                <StyledTextField
                  size="small"
                  label="Miasto"
                  variant="outlined"
                  id="outlined-error"
                  required
                  helperText={
                    error.correspondence_city_name &&
                    error.correspondence_city_name[0] ===
                      "This field may not be blank." &&
                    "To pole nie może być puste"
                  }
                  error={error.correspondence_city_name ? true : false}
                  value={formData.correspondence_city_name}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      correspondence_city_name: event.target.value,
                    });
                  }}
                />
                <StyledZipCodeBox>
                  <StyledZipCodeFirstPart
                    size="small"
                    label="00"
                    variant="outlined"
                    id="outlined-error"
                    required
                    error={error.correspondence_postal_code ? true : false}
                    onChange={(event) => {
                      handlePostalCode(
                        event.target.value,
                        "first",
                        "correspondence_postal_code"
                      );
                    }}
                    inputProps={{ maxLength: 2 }}
                  />
                  <Typography variant="h4"> - </Typography>
                  <StyledZipCodeSecondPart
                    size="small"
                    label="000"
                    variant="outlined"
                    id="outlined-error"
                    required
                    error={error.correspondence_postal_code ? true : false}
                    onChange={(event) => {
                      handlePostalCode(
                        event.target.value,
                        "second",
                        "correspondence_postal_code"
                      );
                    }}
                    inputProps={{ maxLength: 3 }}
                  />
                </StyledZipCodeBox>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "1rem",
              height: "100%",
            }}
          >
            <FormControl
              error={
                error.consent_receive_commercial_information_electronic_means ||
                error.consent_to_contact_via_phone_textmsg
                  ? true
                  : false
              }
              component="fieldset"
            >
              <CountrySelect
                label="Kraj pochodzenia"
                value={country}
                setValue={setCountry}
                handleSet={handleSetCountry}
              />
              <CountrySelect
                label="Obywatelstwo"
                value={citizenShip}
                setValue={setCitizenShip}
                handleSet={handleSetCitizenship}
              />
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">
                  Preferowany język do kontaktu
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="Polski"
                  name="radio-buttons-group"
                  value={
                    formData.preferred_contact_language_ukrainian_or_russian
                  }
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      preferred_contact_language_ukrainian_or_russian:
                        event.target.value,
                    });
                  }}
                >
                  <FormControlLabel
                    value="Polski"
                    control={<Radio />}
                    label="Polski"
                  />
                  <FormControlLabel
                    value="Ukraiński"
                    control={<Radio />}
                    label="Ukraiński"
                  />
                  <FormControlLabel
                    value="Rosyjski"
                    control={<Radio />}
                    label="Rosyjski"
                  />
                </RadioGroup>
              </FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        formData.consent_receive_commercial_information_electronic_means
                      }
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          consent_receive_commercial_information_electronic_means:
                            event.target.checked,
                        });
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Zgoda na informację handlową"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.consent_to_contact_via_phone_textmsg}
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          consent_to_contact_via_phone_textmsg:
                            event.target.checked,
                        });
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Zgoda kontakt tel. i SMS"
                />
              </FormGroup>
              {/* {error.consent_receive_commercial_information_electronic_means || error.consent_to_contact_via_phone_textmsg ? <FormHelperText>Obie zgody są obowiązkowe</FormHelperText> : ''} */}
            </FormControl>
            <StyledTextField
              multiline
              size="small"
              label="Uwagi/Komentarz"
              rows={5}
              variant="outlined"
              value={formData.seller_comment}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  seller_comment: event.target.value,
                });
              }}
            />
          </Box>
        </Box>
        <Box sx={{ position: "relative" }}>
          <CustomButton
            disabled={isLoading}
            variant="contained"
            width="100%"
            label="Zapisz"
            icon={<SaveIcon />}
            handleClick={() => {
              handlePostClient();
            }}
          />
          {isLoading && (
            <CircularProgress
              size={24}
              sx={{
                color: green[800],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </Dialog>
      <SnackbarPositive
        isOpen={isOpen}
        handleCloseSnackbar={handleCloseSnackbar}
        snackbarMessage={"Dodano nowego klienta"}
      />
    </Box>
  );
};

export default FormDialog;
