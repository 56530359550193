import styled from "@emotion/styled";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CancelPaymentLink from "../../api/CancelPaymentLink";
import PutClient from "../../api/PutClient";
import { CustomButton } from "../buttons/CustomButton";
import CountrySelect, { getCountryByCode } from "./CountrySelector";
import SnackbarError from "../snackbars/SnackbarError";
import { Errors } from "../../api/error";

const StyledZipCodeBox = styled(Box)(({}) => ({
  maxWidth: "100%",
  display: "flex",
  alignItems: "center",
}));

const StyledZipCodeFirstPart = styled(TextField)(({}) => ({
  maxWidth: "4rem",
}));

const StyledZipCodeSecondPart = styled(TextField)(({}) => ({
  maxWidth: "5rem",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  minWidth: "10rem",
  width: "100%",
  margin: "0.5rem",
}));

const ClientData = ({
  clientId,
  clientData,
  setClientData,
  handleOpenSnackbarPositive,
  setReload,
}) => {
  const navigate = useNavigate();
  const [zipCodeAddress, setZipCodeAddress] = useState([]);
  const [zipCodeCorrespondence, setZipCodeCorrespondence] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState({
    phone_number: "",
    contact_email: "",
    invoice_email: "",
    address_street_name: "",
    address_house_number: "",
    address_apartment_number: "",
    address_city_name: "",
    address_postal_code: "",
    correspondence_street_name: "",
    correspondence_house_number: "",
    correspondence_apartment_number: "",
    correspondence_city_name: "",
    correspondence_postal_code: "",
    consent_receive_commercial_information_electronic_means: true,
    consent_to_contact_via_phone_textmsg: true,
    seller_comment: "",
  });

  const [putClientError, setPutClientError] = useState(null);

  const resetErrors = () => {
    setError({
      phone_number: "",
      contact_email: "",
      invoice_email: "",
      address_street_name: "",
      address_house_number: "",
      address_apartment_number: "",
      address_city_name: "",
      address_postal_code: "",
      correspondence_street_name: "",
      correspondence_house_number: "",
      correspondence_apartment_number: "",
      correspondence_city_name: "",
      correspondence_postal_code: "",
      consent_receive_commercial_information_electronic_means: true,
      consent_to_contact_via_phone_textmsg: true,
      seller_comment: "",
    });
  };

  const clientCountryDefault = getCountryByCode(clientData.country);
  const clientCitizenShipDefault = getCountryByCode(clientData.citizenship);

  const [country, setCountry] = useState(clientCountryDefault);
  const [citizenShip, setCitizenShip] = useState(clientCitizenShipDefault);

  const handleSetCountry = (val) => {
    setClientData({
      ...clientData,
      country: val.code,
    });
  };
  const handleSetCitizenship = (val) => {
    setClientData({
      ...clientData,
      citizenship: val.code,
    });
  };

  const parsePesel = () => {
    if (clientData.pesel.length < 11) {
      setError({ ...error, pesel: "Podany numer PESEL jest błędny" });
    } else {
      var aInt = new Array();
      for (let i = 0; i < 11; i++) {
        aInt[i] = parseInt(clientData.pesel.substring(i, i + 1));
      }
      var wagi = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 1];
      var sum = 0;
      for (let i = 0; i < 11; i++) sum += wagi[i] * aInt[i];
      if (sum % 10 !== 0) {
        setError({ ...error, pesel: "Podany numer PESEL jest błędny" });
      }
      let year = 1900 + aInt[0] * 10 + aInt[1];
      if (aInt[2] >= 2 && aInt[2] < 8) year += Math.floor(aInt[2] / 2) * 100;
      if (aInt[2] >= 8) year -= 100;

      var month = (aInt[2] % 2) * 10 + aInt[3];
      var day = aInt[4] * 10 + aInt[5];
      var gender = aInt[9] % 2 === 1 ? "M" : "K";
      setClientData({
        ...clientData,
        birth_date: `${year}-${
          month.toString().length === 1 ? `0${month}` : month
        }-${day.toString().length === 1 ? `0${day}` : day}`,
        gender_field: gender,
      });
    }
  };

  const handlePostalCode = (value, part, field) => {
    if (clientData[field]) {
      const [firstPart, secondPart] = clientData[field].split("-");
      const newPostal =
        part === "first" ? `${value}-${secondPart}` : `${firstPart}-${value}`;
      setClientData((prevState) => ({
        ...prevState,
        [field]: newPostal,
      }));
    } else {
      setClientData((prevState) => ({
        ...prevState,
        [field]: part === "first" ? `${value}-` : `-${value}`,
      }));
    }
  };

  const unpackZipCode = () => {
    setZipCodeAddress(clientData.address_postal_code.split("-"));
    setZipCodeCorrespondence(clientData.correspondence_postal_code.split("-"));
  };

  const handlePostEditedClientData = () => {
    if (clientData) {
      setError("");
      PutClient(clientId, clientData)
        .then((res) => {
          handleOpenSnackbarPositive("Edytowano dane klienta");
          if (clientData.status === 4) {
            CancelPaymentLink(clientId)
              .then((res) => {
                if (res) {
                  setReload((prev) => !prev);
                }
              })
              .catch((err) => {
                // console.log(err);
              });
          }
          handleEditMode();
        })
        .catch((err) => {
          if (err.response.status === Errors.BAD_REQUEST) {
            setError(err.response.data.error.details);
            setPutClientError("Wystąpił problem w trakcie zapisu danych");
          }

          if (err.response.status === Errors.PESEL_ALREADY_EXISTS) {
            setError({
              pesel: ["client with this pesel already exists."],
            });
            setPutClientError("Klient o tym numerze PESEL już istnieje");
          }
        });
    }
  };

  const handleEditMode = () => {
    setEditMode((prev) => !prev);
    unpackZipCode();
  };

  return (
    <div>
      <SnackbarError
        error={putClientError}
        onClose={() => setPutClientError(null)}
      />
      {clientData && (
        <List
          sx={{
            padding: "1rem",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
              maxWidth: "300px",
            }}
          >
            <Typography variant="h4">Dane personalne</Typography>
            <Divider flexItem orientation="horizontal" />
            <ListItem>
              {!editMode ? (
                <ListItemText
                  primary={`${clientData.first_name}`}
                  secondary="Imię"
                />
              ) : (
                <TextField
                  size="small"
                  label="Imię"
                  variant="outlined"
                  id="outlined-error"
                  InputLabelProps={{ shrink: true }}
                  helperText={error.first_name && "To pole nie może być puste"}
                  error={error.first_name ? true : false}
                  type="text"
                  required
                  value={clientData.first_name}
                  onChange={(event) => {
                    setClientData({
                      ...clientData,
                      first_name: event.target.value,
                    });
                  }}
                />
              )}
            </ListItem>
            <Divider flexItem orientation="horizontal" />
            <ListItem>
              {!editMode ? (
                <ListItemText
                  primary={`${clientData.last_name}`}
                  secondary="Nazwisko"
                />
              ) : (
                <TextField
                  size="small"
                  label="Nazwisko"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-error"
                  required
                  helperText={error.last_name && "To pole nie może być puste"}
                  error={error.last_name ? true : false}
                  value={clientData.last_name}
                  onChange={(event) => {
                    setClientData({
                      ...clientData,
                      last_name: event.target.value,
                    });
                  }}
                />
              )}
            </ListItem>
            <Divider flexItem orientation="horizontal" />
            <ListItem>
              {!editMode ? (
                <ListItemText
                  primary={`${
                    clientData.pesel ? clientData.pesel : clientData.passport
                  }`}
                  secondary={`${clientData.pesel ? "Pesel" : "Paszport"}`}
                />
              ) : (
                <TextField
                  sx={{
                    '& input[type="number"]::-webkit-inner-spin-button': {
                      display: "none",
                    },
                    "& input[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  }}
                  size="small"
                  label="Pesel"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-error"
                  type="number"
                  required
                  helperText={
                    error.pesel
                      ? error.pesel[0] ===
                        "client with this pesel already exists."
                        ? "Klient o tym numerze PESEL już istnieje"
                        : error.pesel[0] === "This field may not be blank."
                        ? "To pole nie może być puste"
                        : "Podany numer PESEL jest błędny"
                      : "Naciśnij Enter, by uzupełnić datę urodzenia i płeć na podstawie peselu"
                  }
                  error={error.pesel ? true : false}
                  value={clientData.pesel}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      parsePesel();
                    }
                  }}
                  onChange={(event) => {
                    setClientData({ ...clientData, pesel: event.target.value });
                  }}
                />
              )}
            </ListItem>
            <Divider flexItem orientation="horizontal" />
            <ListItem>
              {!editMode ? (
                <ListItemText
                  primary={`${clientData.birth_date}`}
                  secondary="Data urodzenia"
                />
              ) : (
                <TextField
                  size="small"
                  label="Data urodzenia"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-error"
                  required
                  helperText={
                    error.birth_date
                      ? error.birth_date[0] ===
                          "Date has wrong format. Use one of these formats instead: YYYY-MM-DD." &&
                        "Błędny format daty"
                      : "Podaj datę w formacie RRRR-MM-DD"
                  }
                  error={error.birth_date ? true : false}
                  value={clientData.birth_date}
                  onChange={(event) => {
                    setClientData({
                      ...clientData,
                      birth_date: event.target.value,
                    });
                  }}
                />
              )}
            </ListItem>
            <Divider flexItem orientation="horizontal" />

            <ListItem>
              {!editMode ? (
                <ListItemText
                  primary={`${clientData.gender_field}`}
                  secondary="Płeć"
                />
              ) : (
                <FormControl
                  required
                  error={
                    error.gender_field && error.gender_field[0] ? true : false
                  }
                >
                  <FormLabel id="demo-radio-buttons-group-label">
                    Płeć
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    value={clientData.gender_field}
                    onChange={(event) => {
                      setClientData({
                        ...clientData,
                        gender_field: event.target.value,
                      });
                    }}
                  >
                    <FormControlLabel
                      value="K"
                      control={<Radio />}
                      label="Kobieta"
                    />
                    <FormControlLabel
                      value="M"
                      control={<Radio />}
                      label="Mężczyzna"
                    />
                  </RadioGroup>

                  <FormHelperText sx={{ color: "red" }}>
                    {error.gender_field &&
                      (error.gender_field[0] === "Pesel - gender mismatch"
                        ? "Zaznaczona płeć nie zgadza się z numerem PESEL."
                        : "Wybierz jedną z podanych opcji")}
                  </FormHelperText>
                </FormControl>
              )}
            </ListItem>
            {!editMode && <Divider flexItem orientation="horizontal" />}
            <ListItem>
              {!editMode ? (
                <ListItemText
                  primary={`${clientData.phone_number}`}
                  secondary="Numer telefonu"
                />
              ) : (
                <TextField
                  label="Numer telefonu"
                  size="small"
                  fullWidth
                  required
                  helperText={
                    error.phone_number
                      ? error.phone_number[0] === "A valid integer is required."
                        ? "To pole nie może być puste"
                        : "Numer jest nie poprawny"
                      : ""
                  }
                  error={error.phone_number ? true : false}
                  value={clientData.phone_number}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setClientData({
                      ...clientData,
                      phone_number: e.target.value,
                    });
                  }}
                />
              )}
            </ListItem>
            {!editMode && <Divider flexItem orientation="horizontal" />}
            <ListItem>
              {!editMode ? (
                <ListItemText
                  primary={`${clientData.contact_email}`}
                  secondary="Email do kontaktu"
                />
              ) : (
                <TextField
                  label="Email do kontaktu"
                  size="small"
                  fullWidth
                  required
                  helperText={
                    error.contact_email
                      ? error.contact_email[0] ===
                        "This field may not be blank."
                        ? "To pole nie może byc puste"
                        : "Adres email jest nie poprawny"
                      : ""
                  }
                  error={error.contact_email ? true : false}
                  value={clientData.contact_email}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setClientData({
                      ...clientData,
                      contact_email: e.target.value,
                    });
                  }}
                />
              )}
            </ListItem>
            {!editMode && <Divider flexItem orientation="horizontal" />}
            <ListItem>
              {!editMode ? (
                <ListItemText
                  primary={`${clientData.invoice_email}`}
                  secondary="Email do faktury"
                />
              ) : (
                <TextField
                  label="Email do faktury"
                  size="small"
                  fullWidth
                  required
                  helperText={
                    error.invoice_email
                      ? error.invoice_email[0] ===
                        "This field may not be blank."
                        ? "To pole nie może byc puste"
                        : "Adres email jest nie poprawny"
                      : ""
                  }
                  error={error.invoice_email ? true : false}
                  value={clientData.invoice_email}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setClientData({
                      ...clientData,
                      invoice_email: e.target.value,
                    });
                  }}
                />
              )}
            </ListItem>
            {!editMode && <Divider flexItem orientation="horizontal" />}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1rem",
                }}
              >
                <Typography variant="h4">Adres</Typography>
                <Divider flexItem orientation="horizontal" />
                <ListItem>
                  {!editMode ? (
                    <ListItemText
                      primary={`${clientData.address_street_name} ${
                        clientData.address_house_number
                      }${clientData.address_apartment_number && "/"}${
                        clientData.address_apartment_number
                      }`}
                      secondary="Ulica i numer"
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Ulica"
                        size="small"
                        required
                        helperText={
                          error.address_street_name &&
                          "To pole nie może byc puste"
                        }
                        error={error.address_street_name ? true : false}
                        value={clientData.address_street_name}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                          setClientData({
                            ...clientData,
                            address_street_name: e.target.value,
                          });
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Numer budynku"
                        size="small"
                        required
                        helperText={
                          error.address_house_number &&
                          "To pole nie może byc puste"
                        }
                        error={error.address_house_number ? true : false}
                        value={clientData.address_house_number}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                          setClientData({
                            ...clientData,
                            address_house_number: e.target.value,
                          });
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Numer mieszkania"
                        size="small"
                        value={clientData.address_apartment_number}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                          setClientData({
                            ...clientData,
                            address_apartment_number: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  )}
                </ListItem>
                {!editMode && <Divider flexItem orientation="horizontal" />}
                <ListItem>
                  {!editMode ? (
                    <ListItemText
                      primary={`${clientData.address_city_name} ${clientData.address_postal_code}`}
                      secondary="Miasto i kod pocztowy"
                    />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        width: "100%",
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Miasto"
                        size="small"
                        required
                        helperText={
                          error.address_city_name &&
                          "To pole nie może byc puste"
                        }
                        error={error.address_city_name ? true : false}
                        value={clientData.address_city_name}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                          setClientData({
                            ...clientData,
                            address_city_name: e.target.value,
                          });
                        }}
                      />
                      <StyledZipCodeBox>
                        <StyledZipCodeFirstPart
                          size="small"
                          label="00"
                          variant="outlined"
                          id="outlined-error"
                          required
                          error={error.address_postal_code ? true : false}
                          value={zipCodeAddress[0]}
                          inputProps={{ maxLength: 2 }}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => {
                            setZipCodeAddress((prevState) => [
                              e.target.value,
                              prevState[1],
                            ]);
                            handlePostalCode(
                              e.target.value,
                              "first",
                              "address_postal_code"
                            );
                          }}
                        />
                        <Typography variant="h4"> - </Typography>
                        <StyledZipCodeSecondPart
                          size="small"
                          label="000"
                          variant="outlined"
                          id="outlined-error"
                          max="3"
                          required
                          error={error.address_postal_code ? true : false}
                          value={zipCodeAddress[1]}
                          inputProps={{ maxLength: 3 }}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => {
                            setZipCodeAddress((prevState) => [
                              prevState[0],
                              e.target.value,
                            ]);
                            handlePostalCode(
                              e.target.value,
                              "second",
                              "address_postal_code"
                            );
                          }}
                        />
                      </StyledZipCodeBox>
                    </Box>
                  )}
                </ListItem>
                {!editMode && <Divider flexItem orientation="horizontal" />}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1rem",
                }}
              >
                <Typography variant="h4">Adres do korespondencji</Typography>
                <Divider flexItem orientation="horizontal" />
                <ListItem>
                  {!editMode ? (
                    <ListItemText
                      primary={`${clientData.correspondence_street_name} ${
                        clientData.correspondence_house_number
                      }${clientData.correspondence_apartment_number && "/"}${
                        clientData.correspondence_apartment_number
                      }`}
                      secondary="Ulica i numer"
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Ulica"
                        size="small"
                        required
                        helperText={
                          error.correspondence_street_name &&
                          "To pole nie może być puste"
                        }
                        error={error.correspondence_street_name ? true : false}
                        value={clientData.correspondence_street_name}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                          setClientData({
                            ...clientData,
                            correspondence_street_name: e.target.value,
                          });
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Numer budynku"
                        size="small"
                        required
                        helperText={
                          error.correspondence_house_number &&
                          "To pole nie może być puste"
                        }
                        error={error.correspondence_house_number ? true : false}
                        value={clientData.correspondence_house_number}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                          setClientData({
                            ...clientData,
                            correspondence_house_number: e.target.value,
                          });
                        }}
                      />
                      <TextField
                        fullWidth
                        label="Numer mieszkania"
                        size="small"
                        value={clientData.correspondence_apartment_number}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                          setClientData({
                            ...clientData,
                            correspondence_apartment_number: e.target.value,
                          });
                        }}
                      />
                    </Box>
                  )}
                </ListItem>
                {!editMode && <Divider flexItem orientation="horizontal" />}
                <ListItem>
                  {!editMode ? (
                    <ListItemText
                      primary={`${clientData.correspondence_city_name} ${clientData.correspondence_postal_code}`}
                      secondary="Miasto i kod pocztowy"
                    />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        width: "100%",
                      }}
                    >
                      <TextField
                        fullWidth
                        label="Miasto"
                        size="small"
                        required
                        helperText={
                          error.correspondence_city_name &&
                          "To pole nie może być puste"
                        }
                        error={error.correspondence_city_name ? true : false}
                        value={clientData.correspondence_city_name}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                          setClientData({
                            ...clientData,
                            correspondence_city_name: e.target.value,
                          });
                        }}
                      />
                      <StyledZipCodeBox>
                        <StyledZipCodeFirstPart
                          size="small"
                          label="00"
                          variant="outlined"
                          id="outlined-error"
                          required
                          error={error.correspondence_postal_code}
                          InputLabelProps={{ shrink: true }}
                          value={zipCodeCorrespondence[0]}
                          inputProps={{ maxLength: 2 }}
                          onChange={(e) => {
                            setZipCodeCorrespondence((prevState) => [
                              e.target.value,
                              prevState[1],
                            ]);
                            handlePostalCode(
                              e.target.value,
                              "first",
                              "correspondence_postal_code"
                            );
                          }}
                        />
                        <Typography variant="h4"> - </Typography>
                        <StyledZipCodeSecondPart
                          size="small"
                          label="000"
                          variant="outlined"
                          id="outlined-error"
                          max="3"
                          required
                          error={error.correspondence_postal_code}
                          InputLabelProps={{ shrink: true }}
                          value={zipCodeCorrespondence[1]}
                          inputProps={{ maxLength: 3 }}
                          onChange={(e) => {
                            setZipCodeCorrespondence((prevState) => [
                              prevState[0],
                              e.target.value,
                            ]);
                            handlePostalCode(
                              e.target.value,
                              "second",
                              "correspondence_postal_code"
                            );
                          }}
                        />
                      </StyledZipCodeBox>
                    </Box>
                  )}
                </ListItem>
                {!editMode && <Divider flexItem orientation="horizontal" />}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "1rem",
              }}
            >
              <Typography variant="h4">Szczegóły</Typography>
              <Divider flexItem orientation="horizontal" />
              <ListItem sx={{ padding: "0 1rem" }}>
                {!editMode ? (
                  <ListItemText
                    primary={clientData.country}
                    secondary="Kraj pochodzenia"
                  />
                ) : (
                  <CountrySelect
                    label="Kraj pochodzenia"
                    value={country}
                    setValue={setCountry}
                    handleSet={handleSetCountry}
                    sx={{ mt: 1 }}
                  />
                )}
              </ListItem>
              <Divider flexItem orientation="horizontal" />
              <ListItem sx={{ padding: "0 1rem" }}>
                {!editMode ? (
                  <ListItemText
                    primary={clientData.citizenship}
                    secondary="Obywatelstwo"
                  />
                ) : (
                  <CountrySelect
                    label="Obywatelstwo"
                    value={citizenShip}
                    setValue={setCitizenShip}
                    handleSet={handleSetCitizenship}
                    sx={{ mt: 1 }}
                  />
                )}
              </ListItem>
              <Divider flexItem orientation="horizontal" />
              <ListItem>
                {!editMode ? (
                  <ListItemText
                    primary={`${clientData.preferred_contact_language_ukrainian_or_russian}`}
                    secondary="Preferowany język"
                  />
                ) : (
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Preferowany język do kontaktu
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="Polski"
                      name="radio-buttons-group"
                      value={
                        clientData.preferred_contact_language_ukrainian_or_russian
                      }
                      onChange={(event) => {
                        setClientData({
                          ...clientData,
                          preferred_contact_language_ukrainian_or_russian:
                            event.target.value,
                        });
                      }}
                    >
                      <FormControlLabel
                        value="Polski"
                        control={<Radio />}
                        label="Polski"
                      />
                      <FormControlLabel
                        value="Ukraiński"
                        control={<Radio />}
                        label="Ukraiński"
                      />
                      <FormControlLabel
                        value="Rosyjski"
                        control={<Radio />}
                        label="Rosyjski"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </ListItem>
              <Divider flexItem orientation="horizontal" />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "1rem",
              }}
            >
              <Typography variant="h4">Zgody marketingowe i uwagi</Typography>
              <Divider flexItem orientation="horizontal" />
              <ListItem sx={{ padding: "0 1rem" }}>
                {!editMode ? (
                  <ListItemText
                    primary={`${
                      clientData.consent_receive_commercial_information_electronic_means ===
                      true
                        ? "Tak"
                        : "Nie"
                    }`}
                    secondary="Zgoda na informację handlową"
                  />
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          clientData.consent_receive_commercial_information_electronic_means
                        }
                        onChange={(event) => {
                          setClientData({
                            ...clientData,
                            consent_receive_commercial_information_electronic_means:
                              event.target.checked,
                          });
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Zgoda na informację handlową"
                  />
                )}
              </ListItem>
              <Divider flexItem orientation="horizontal" />
              <ListItem sx={{ padding: "0 1rem" }}>
                {!editMode ? (
                  <ListItemText
                    primary={`${
                      clientData.consent_to_contact_via_phone_textmsg === true
                        ? "Tak"
                        : "Nie"
                    }`}
                    secondary="Zgoda na kontakt tel. i SMS"
                  />
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          clientData.consent_to_contact_via_phone_textmsg
                        }
                        onChange={(event) => {
                          setClientData({
                            ...clientData,
                            consent_to_contact_via_phone_textmsg:
                              event.target.checked,
                          });
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Zgoda kontakt tel. i SMS"
                  />
                )}
              </ListItem>
              <Divider flexItem orientation="horizontal" />
              <ListItem>
                {!editMode ? (
                  <ListItemText
                    primary={`${clientData.seller_comment}`}
                    secondary="Uwagi"
                  />
                ) : (
                  <StyledTextField
                    multiline
                    size="small"
                    label="Uwagi/Komentarz"
                    rows={5}
                    variant="outlined"
                    value={clientData.seller_comment}
                    onChange={(event) => {
                      setClientData({
                        ...clientData,
                        seller_comment: event.target.value,
                      });
                    }}
                  />
                )}
              </ListItem>
              <Divider flexItem orientation="horizontal" />
            </Box>
          </Box>
        </List>
      )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        {!editMode ? (
          <>
            <Button
              disabled={!clientData.can_renounce && clientData.status !== 8}
              onClick={() => navigate(`/client/${clientId}`)}
              sx={{ color: "white" }}
              endIcon={<DescriptionIcon />}
              variant="contained"
            >
              Odstąpienie
            </Button>
            <CustomButton
              disabled={
                clientData.status === 5 ||
                clientData.status === 6 ||
                clientData.status === 7 ||
                clientData.status === 8
                  ? true
                  : false
              }
              label="Edytuj dane klienta"
              icon={<EditIcon />}
              handleClick={handleEditMode}
            ></CustomButton>
          </>
        ) : (
          <>
            <CustomButton
              label="Zapisz"
              icon={<SaveIcon />}
              handleClick={() => {
                handlePostEditedClientData();
              }}
            ></CustomButton>
            <Button
              onClick={() => {
                setReload((prev) => !prev);
                resetErrors();
                handleEditMode();
              }}
            >
              Anuluj
            </Button>
          </>
        )}
      </Box>
      {editMode && <Box sx={{ height: "4rem", width: "90%" }} />}
    </div>
  );
};

export default ClientData;
