import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import PatchClientPackage from "../../api/PatchClientPackage";
import { format } from "date-fns";

const EDIT_ENABLED_ENUMS = [1, 3, 4, 7];

const validateDate = (date) => /^\d{4}-\d{2}-\d{2}$/.test(date);

const validateForm = (data) => ({
  start_date: validateDate(data.start_date)
    ? null
    : `Podaj datę w formacie YYYY-MM-DD, np. ${format(
        new Date(),
        "yyyy-MM-dd"
      )}`,
});

const EditClientPackageDialog = ({
  clientId,
  name,
  open,
  onClose,
  onSuccess,
  defaultData,
  ...props
}) => {
  const [formData, setFormData] = useState(defaultData);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setFormData(defaultData);
      setErrors({});
    }
  }, [open, defaultData]);

  const onChange = ({ target: { name, value } }) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, ...validateForm({ [name]: value }) }));
  };

  const onSubmit = useCallback(async () => {
    const validationErrors = validateForm(formData);
    if (Object.values(validationErrors).some(Boolean)) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      await PatchClientPackage(clientId, formData);
      onClose?.();
      onSuccess?.();
    } catch {
      setErrors((prev) => ({
        ...prev,
        patch: "Błąd żądania, spróbuj ponownie",
      }));
    } finally {
      setLoading(false);
    }
  }, [clientId, formData, onClose, onSuccess]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{ sx: { maxWidth: "30rem" } }}
      {...props}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          px: "1.2rem",
          pt: "1rem",
        }}
      >
        <DialogTitle sx={{ padding: 0 }}>Edytuj pakiet klienta</DialogTitle>
        <Typography variant="body2">{name}</Typography>
      </Box>
      <DialogContent>
        <TextField
          fullWidth
          label="Data rozpoczęcia sprzedaży - YYYY-MM-DD"
          type="text"
          required
          variant="standard"
          name="start_date"
          value={formData.start_date || ""}
          onChange={onChange}
          helperText={errors.start_date || ""}
          error={Boolean(errors.start_date)}
        />
      </DialogContent>
      <DialogActions sx={{ px: "1.1rem", pt: "2rem", gap: 0 }}>
        <Box sx={{ display: "flex", flex: 1 }}>
          {errors.patch && (
            <Typography variant="body2" sx={{ color: "error.main" }}>
              {errors.patch}
            </Typography>
          )}
        </Box>
        <Button onClick={onClose}>Zamknij</Button>
        <Button
          onClick={onSubmit}
          endIcon={loading ? <CircularProgress size="0.9rem" /> : <SaveIcon />}
          disabled={loading || Object.values(errors).some(Boolean)}
        >
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditClientPackageModule = ({
  clientId,
  clientStatus,
  name,
  defaultData,
  onSuccess,
}) => {
  const [open, setOpen] = useState(false);
  const toggleEditModal = useCallback(() => setOpen((prev) => !prev), []);

  if (!EDIT_ENABLED_ENUMS.includes(clientStatus)) {
    return <></>;
  }

  return (
    <>
      <EditClientPackageDialog
        clientId={clientId}
        name={name}
        defaultData={defaultData}
        open={open}
        onClose={toggleEditModal}
        onSuccess={onSuccess}
      />
      <Button onClick={toggleEditModal}>Edytuj pakiet</Button>
    </>
  );
};

export default EditClientPackageModule;
